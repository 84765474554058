<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-100">
    <body class="h-full">
    ```
    -->
    <Html class="h-full bg-gray-50">
        <div class="min-h-full">
            <Disclosure as="nav" class="bg-blue-800" v-slot="{ open }">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="flex items-center justify-between h-16">
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <NuxtLink to="/">
                                    <svg
                                        version="1.1"
                                        viewBox="0 0 60 60"
                                        class="svg-icon svg-fill"
                                        style="width: 40px; height: 40px;"
                                    >
                                        <g fill="#FFF" fill-rule="nonzero">
                                            <path
                                                pid="0"
                                                d="M36.114.664C34.724.362 33.336.242 31.886 0h-3.744c-1.57.242-3.14.423-4.71.725C11.716 3.26 2.234 13.285.422 25.06.243 26.087.122 27.113 0 28.14v3.744c.242 1.51.423 3.019.725 4.468C3.32 48.43 12.984 57.548 25.183 59.601c14.675 2.415 28.987-6.34 33.396-20.471C63.954 22.1 53.567 4.348 36.114.664zm-6.1 55.434c-14.433 0-26.089-11.654-26.089-26.086 0-14.432 11.716-26.147 26.15-26.087 14.373 0 26.028 11.715 26.028 26.087 0 14.371-11.655 26.086-26.089 26.086z"
                                            />
                                            <path
                                                pid="1"
                                                d="M37.34 41.587c-2.673.363-6.237-2.118-7.306-5.022.179-.06.357-.121.476-.182 5.7-2.238 8.967-8.047 7.898-14.097C37.339 16.296 32.232 12 26.174 12H14v4.114h.89c3.861 0 7.721-.06 11.522 0 4.395.06 7.898 3.63 8.077 8.047.178 4.417-3.03 8.229-7.424 8.713-.594.06-1.188.06-1.722.121-.356 1.755.356 4.72 1.722 6.837 2.376 3.812 5.82 5.748 10.333 6.05V48c2.554-1.452 5.048-2.783 7.602-4.235-2.613-1.452-5.048-2.844-7.66-4.236v2.058z"
                                            />
                                        </g>
                                    </svg>
                                </NuxtLink>
                            </div>
                            <div class="hidden md:block">
                                <div class="ml-10 flex items-baseline space-x-4">
                                    <NuxtLink
                                        v-for="item in navigation"
                                        :key="item.name"
                                        :to="item.href"
                                        :class="[item.activeRoutes.includes($route.name) ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-500 hover:bg-opacity-75', 'px-3 py-2 rounded-md text-sm font-medium']"
                                        :aria-current="item.activeRoutes.includes($route.name) ? 'page' : undefined"
                                    >{{ item.name }}</NuxtLink>
                                </div>
                            </div>
                        </div>
                        <div class="hidden md:block">
                            <div class="ml-4 flex items-center md:ml-6">
                                <button
                                    type="button"
                                    class="p-1 bg-blue-600 rounded-full text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white"
                                >
                                    <span class="sr-only">View notifications</span>
                                    <BellIcon class="h-6 w-6" aria-hidden="true" />
                                </button>

                                <!-- Profile dropdown -->
                                <Menu as="div" class="ml-3 relative">
                                    <div>
                                        <MenuButton
                                            class="max-w-xs bg-blue-600 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white"
                                        >
                                            <span class="sr-only">Open user menu</span>
                                            <img class="h-8 w-8 rounded-full" :src="user.imageUrl" />
                                        </MenuButton>
                                    </div>
                                    <transition
                                        enter-active-class="transition ease-out duration-100"
                                        enter-from-class="transform opacity-0 scale-95"
                                        enter-to-class="transform opacity-100 scale-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-from-class="transform opacity-100 scale-100"
                                        leave-to-class="transform opacity-0 scale-95"
                                    >
                                        <MenuItems
                                            class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                            <MenuItem
                                                v-for="item in userNavigation"
                                                :key="item.name"
                                                v-slot="{ active }"
                                            >
                                                <a
                                                    :href="item.href"
                                                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                                >{{ item.name }}</a>
                                            </MenuItem>
                                        </MenuItems>
                                    </transition>
                                </Menu>
                            </div>
                        </div>
                        <div class="-mr-2 flex md:hidden">
                            <!-- Mobile menu button -->
                            <DisclosureButton
                                class="bg-blue-600 inline-flex items-center justify-center p-2 rounded-md text-blue-200 hover:text-white hover:bg-blue-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white"
                            >
                                <span class="sr-only">Open main menu</span>
                                <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                                <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                            </DisclosureButton>
                        </div>
                    </div>
                </div>

                <DisclosurePanel class="md:hidden">
                    <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <DisclosureButton
                            v-for="item in navigation"
                            :key="item.name"
                            as="a"
                            :href="item.href"
                            :class="[item.current ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-500 hover:bg-opacity-75', 'block px-3 py-2 rounded-md text-base font-medium']"
                            :aria-current="item.current ? 'page' : undefined"
                        >{{ item.name }}</DisclosureButton>
                    </div>
                    <div class="pt-4 pb-3 border-t border-blue-700">
                        <div class="flex items-center px-5">
                            <div class="flex-shrink-0">
                                <img class="h-10 w-10 rounded-full" :src="user.imageUrl" />
                            </div>
                            <div class="ml-3">
                                <div class="text-base font-medium text-white">{{ user.name }}</div>
                                <div class="text-sm font-medium text-blue-300">{{ user.email }}</div>
                            </div>
                            <button
                                type="button"
                                class="ml-auto bg-blue-600 flex-shrink-0 p-1 rounded-full text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white"
                            >
                                <span class="sr-only">View notifications</span>
                                <BellIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div class="mt-3 px-2 space-y-1">
                            <DisclosureButton
                                v-for="item in userNavigation"
                                :key="item.name"
                                as="a"
                                :href="item.href"
                                class="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-500 hover:bg-opacity-75"
                            >{{ item.name }}</DisclosureButton>
                        </div>
                    </div>
                </DisclosurePanel>
            </Disclosure>

            <!-- <header class="bg-white shadow-sm">
            <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                <h1 class="text-lg leading-6 font-semibold text-gray-900">Dashboard</h1>
            </div>
            </header>-->

            <slot />
            <!-- <main>
            <div >
              
            </div>
            </main>-->
        </div>
    </Html>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'

// const route = useRoute()


const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
        '/images/profile-photo-placeholder-timcook.jpg',
}
const navigation = [
    { name: 'Dashboard', href: '/', activeRoutes: ['index'] },
    { name: 'Library', href: '/library', activeRoutes: ['library'] },
    // { name: 'Moderation', href: '/moderation', activeRoutes: ['moderation'] },
    { name: 'Delivery', href: '/delivery', activeRoutes: ['delivery'] },
    // { name: 'Manage', href: '/manage', activeRoutes: ['manage'] },
    { name: 'Reports', href: '/reports', activeRoutes: ['reports'] },
]
const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
]



</script>